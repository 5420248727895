<template>
  <div class="d-flex justify-content-center" v-if="loading">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <template v-if="!loading">
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Label</th>
            <th scope="col">Key</th>
            <th scope="col">End Point</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(key, index) in keys" :key="index">
            <th scope="row">{{ key.label }}</th>
            <td>{{ key.keyid }}</td>
            <td>{{ key.gatewaySubDomain }}.api.checkaddress.com.au</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <button type="button" class="btn btn-primary float-end mt-5" data-bs-toggle="modal" data-bs-target="#exampleModal">Create New Key</button>

    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Create API Key</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">Label</label>
                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" v-model="newApiKeyLabel">
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <template v-if="!creatingKey">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" @click="createApiKey" data-bs-dismiss="modal">Create</button>
            </template>
            <template v-else>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
// @ is an alias to /src
import restApi from '@aws-amplify/api-rest'
const apiName = 'CheckAddressConsole'

export default {
  name: 'ApiKeysView',
  components: {},
  data () {
    return {
      loading: true,
      keys: [],
      newApiKeyLabel: undefined,
      creatingKey: false
    }
  },
  methods: {
    createApiKey () {
      this.loading = true
      const path = '/keys'
      const newKeyOptions = {
        body: {
          label: this.newApiKeyLabel
        }
      }
      restApi
        .post(apiName, path, newKeyOptions)
        .then(response => {
          this.newApiKeyLabel = undefined
          // this.creatingKey = false
          this.loadKeys()
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    loadKeys () {
      this.loading = true
      const path = '/keys/:userid'
      const myInit = {
        headers: {},
        response: true,
        queryStringParameters: {}
      }
      restApi
        .get(apiName, path, myInit)
        .then(response => {
          this.keys = response.data
          this.loading = false
          // console.log(response)
        })
        .catch(error => {
          console.log(error.response)
        })
    }
  },
  mounted () {
    this.loadKeys()
  }
}
</script>
