<template>
  <div class="home">
    Dashboard
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'DashboardView',
  components: {}
}
</script>
